
import { Component, Vue } from "vue-property-decorator";

import { EXCEL_DATABASES_URL } from "@/config/urls";

@Component
export default class ExcelExportBdd extends Vue {
  get excelUrl(): string {
    return EXCEL_DATABASES_URL;
  }
}
