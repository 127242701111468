
import { Component, InjectReactive, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { LITE_FEATURE } from "@/domain";
import { ServerConfig } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";

import ExcelExportBdd from "@/components/Excel/DB/ExcelExportBdd.vue";
import ExcelImportBdd from "@/components/Excel/DB/ExcelImportBdd.vue";

@Component({
  components: {
    ExcelImportBdd,
    ExcelExportBdd,
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ExcelBDD extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;
}
