
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import UnimportablesTable from "@/components/Excel/DB/ImportResult/UnimportablesTable.vue";
import UnreadablesTable from "@/components/Excel/DB/ImportResult/UnreadablesTable.vue";

import { FailedSheet } from "@/domain";

@Component({
  components: {
    UnimportablesTable,
    UnreadablesTable,
  },
})
export default class ImportResult extends Vue {
  @Prop({ required: true })
  failedSheets!: FailedSheet[];

  get hasUnreadables(): boolean {
    return _.filter(this.failedSheets, (s) => s.hasUnreadables).length > 0;
  }

  get hasUnimportables(): boolean {
    return _.filter(this.failedSheets, (s) => s.hasUnimportables).length > 0;
  }
}
