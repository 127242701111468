
import { Component, Inject, Ref, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import ImportResult from "@/components/Excel/DB/ImportResult/ImportResult.vue";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { isXlsx } from "@/tools";
import { FailedSheet } from "@/domain";

import { IExcelDatabaseService } from "@/services";

@Component({
  components: {
    ImportResult,
  },
})
export default class ExcelImportBdd extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  get excelImportBDDService() {
    return this.container.resolve<IExcelDatabaseService>(
      S.EXCEL_DATABASE_SERVICE
    );
  }

  get hasFailedData() {
    return _.filter(this.failedSheets, (s) => s.hasFailedData).length > 0;
  }

  file: File | null = null;
  loading: boolean = false;
  valid: boolean = true;
  success: boolean = false;
  failedSheets: FailedSheet[] = [];

  isAllowed() {
    return isXlsx(this.file);
  }

  async importFile() {
    try {
      this.loading = true;
      this.success = false;
      if (this.form.validate()) {
        this.failedSheets = await this.excelImportBDDService.import(this.file!);
        this.success = !this.hasFailedData;
      }
    } finally {
      this.loading = false;
    }
  }
}
