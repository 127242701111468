
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { FailedSheet } from "@/domain";

@Component
export default class UnreadablesTable extends Vue {
  @Prop({ required: true })
  failedSheets!: FailedSheet[];

  get items(): { sheet: string; lineNumber: number; line: string }[] {
    return _.chain(this.failedSheets)
      .filter((s) => s.hasUnreadables)
      .flatMap((s) => {
        return s.unreadables.flatMap((u) => {
          return {
            sheet: s.sheetName,
            lineNumber: u.lineNumber,
            line: u.line,
          };
        });
      })
      .value();
  }

  get headers() {
    return [
      {
        text: "Onglet",
        align: "center",
        sortable: true,
        value: "sheet",
      },
      {
        text: "Numéro de ligne",
        align: "center",
        sortable: true,
        value: "lineNumber",
      },
      {
        text: "Valeur",
        align: "center",
        sortable: true,
        value: "line",
      },
    ];
  }
}
